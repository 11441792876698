<template>
  <div :class="getPartner.name">
    <NavbarJourney 
      :telephone="getPhone" 
      :friendly-telephone="getFriendlyPhone" 
      :telephone-color="dynamicColor"
    />
    <div
      class="container confirmation-page confirmation-page--pad-top fade-in"
      :class="{'confirmation-page-money-test': isMoneyTest}"
    >
      <QuestionTitle 
        :title="title" 
        :subtitle="telephoneTypeMessage"
        :title-color-class="titleColor"
      />
      <div class="confirmation-page__containers">
        <!-- contact us -->
        <div class="input-container confirmation-page__container confirmation-page__container--second confirmation-page__container--sm">
          <div class="content-card content-card--white">
            <ContactUs 
              :telephone="getPhone"
              :friendly-telephone="getFriendlyPhone"
              :msg="'If you prefer to contact us:'"
            />
          </div>
        </div>
        <!-- what to expect -->
        <div class="input-container confirmation-page__container confirmation-page__container--first">
          <h3 
            class="confirmation-page__title confirmation-page__title--sm"
          >
            What to expect on this call:
          </h3>
          <BulletPointOrdered 
            class="bullet-gap"
            :bullet-points="bulletPoints"
            :partner="getPartner"
          />
        </div>
        <!-- company name & borrow amount -->
        <div class="input-container confirmation-page__container confirmation-page__container--second">
          <h3 class="confirmation-page__title confirmation-page__title--company-name confirmation-page__title--capitalize confirmation-page__title--blue">
            {{ search | toLowerCase }}
          </h3>
          <div class="content-card">
            <p class="content-card__title">
              Borrow amount:
            </p>
            <h3 class="confirmation-page__title confirmation-page__title--blue mb-0">
              £{{ amount | moneyFormat }} 
            </h3>
          </div>
          <p class="confirmation-page__loan-detail">
            Borrow timeframe: 
            <span class="confirmation-page__loan-detail--highlight">{{ when }}</span> 
          </p>
          <p class="confirmation-page__loan-detail">
            Finance type: 
            <span class="confirmation-page__loan-detail--highlight">
              {{ purpose }}
            </span> 
          </p>
        </div>
      </div>
      <TrustPilot
        class="confirmation-page__trust-pilot"
      />
    </div>
  </div>
</template>

<script>
import isOpeningHours from '@/helpers/openTimes/openingHours'
import { CORPORATE_PHONE_MESSAGE, NON_CORPORATE_PHONE_MESSAGE } from '@/helpers/constants/phone-calls'
import { AMOUNT, WHEN, PURPOSE, SEARCH, TURNOVER } from '@/helpers/constants/question-slugs'
import { ASSETFINANCE } from '@/helpers/constants/partner-names'
import checkCorporate from '@/helpers/corporate/checkCorporate'
import moneyFormat from '@/helpers/filters/moneyFormat'
import toLowerCase from '@/helpers/filters/toLowerCase'
import NavbarJourney from '@/components/shared/NavbarJourney'
import BulletPointOrdered from '@/components/shared/BulletPointOrdered'
import ContactUs from '@/components/shared/ContactUs'
import TrustPilot from '@/components/results/TrustPilot'
import QuestionTitle from '@/components/question/QuestionTitle'

import { mapGetters } from 'vuex'

export default {
  name: 'CallbackConfirmation',
  components: {
    NavbarJourney,
    BulletPointOrdered,
    ContactUs,
    TrustPilot,
    QuestionTitle
  },
  filters: {
    moneyFormat,
    toLowerCase
  },
  data () {
    return {
      turnover: null,
      isOpen: null,
      amount: null,
      search: null,
      firstName: null,
      when: null,
      purpose: null,
      ASSETFINANCE
    }
  },
  computed: {
    ...mapGetters(['getPhone', 'getFriendlyPhone', 'customerFirstName', 'getPartner', 'isMoneyTest', 'dynamicColor', 'titleColor']),
    title () {
      return `Thanks ${this.firstName}. Your Personal lending manager will 
        ${this.isOpen ? 'give you a call shortly.' : 'call you when we are open.'}`
    },
    bulletPoints () {
      return [
        `Your lending manager will confirm your details and ask questions to understand your business in more detail.`,
        'They will walk you through the current lending landscape and explore your potential lending options.',
        'They’ll review our panel of over 100 lenders to find the right match for your business.'
      ]
    },
    telephoneTypeMessage () {
      let telephoneTypeMessage
      const turnOverAmount = Number(this.turnover)

      if (this.getPartner && this.getPartner.name === this.ASSETFINANCE) {
        telephoneTypeMessage = CORPORATE_PHONE_MESSAGE
      } else if (checkCorporate(turnOverAmount)) {
        telephoneTypeMessage = CORPORATE_PHONE_MESSAGE
      } else {
        telephoneTypeMessage = NON_CORPORATE_PHONE_MESSAGE
      }

      return telephoneTypeMessage
    }
  },
  created () {
    this.isOpen = isOpeningHours()
    this.setLocalStorage('qid_amount', AMOUNT)
    this.setLocalStorage('qid_search', SEARCH, 'name')
    // this.setLocalStorage('qid_turnover', TURNOVER)
    this.setLocalStorage('qid_when', WHEN)
    this.setLocalStorage('qid_purpose', PURPOSE)
  },
  mounted () {
    this.getLocalStorage(SEARCH)
    this.setLocalStorageCustomerFirstName()
    this.getLocalStorage(AMOUNT)
    this.getLocalStorage('firstName')
    this.getLocalStorage(TURNOVER)
    this.getLocalStorage(WHEN)
    this.getLocalStorage(PURPOSE)
  },
  methods: {
    setLocalStorage (qid, localStorageName, resultExtra) {
      if (!this.$store.getters.getAnswerById(qid) && localStorage.getItem(localStorageName) === null) return 
      // if quid_name has been set
      if (this.$store.getters.getAnswerById(qid)) {
        // check if needed to go into result obj else use result
        let quidAnswer
        if (!resultExtra) {
          quidAnswer = this.$store.getters.getAnswerById(qid).result
        } else {
          quidAnswer = this.$store.getters.getAnswerById(qid).result[resultExtra]
        }
        // store in local storage
        localStorage.setItem(localStorageName, quidAnswer)
      }
    },
    getLocalStorage (localStorageName) {
      // if there is an item in local storage
      if (localStorage.getItem(localStorageName)) {
      // set amount
        this[localStorageName] = localStorage.getItem(localStorageName)
      } 
    },
    setLocalStorageCustomerFirstName () {
      // if customer name has been set
      if (this.customerFirstName) {
        // store in local storage
        localStorage.setItem('firstName', this.customerFirstName)
      }
    }
  }
}
</script>

<style lang="scss">
  .bullet-gap {
    .bullet-point {
      margin-bottom: 31px;

      &:last-child {
        margin-bottom: 0;
      }

      &__point {
         @media (max-width: 500px) {
          min-width: 20px;
          min-height: 20px;
          width: 20px;
          height: 20px;
          font-size: 16px;
          margin-right: 10px;
         }
      }

      &__content {
        @media (max-width: 500px) {
          font-size: 13px;
          line-height: 17px;
          letter-spacing: -0.45px;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    @media (max-width: 500px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .confirmation-page {
    min-height: 100%;

    &__containers {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 20px;

       @media (max-width: 1199px) {
        align-content: center;
        flex-direction: column-reverse;
      }

      @media (min-width: 1200px) {
        margin-top: 40px;
        flex-direction: row;
      }
    }

    &__container {
      padding: 20px 24px;
      flex-direction: column;
      margin: 0;

       @media (min-width: 501px) {
        padding: 45px;
        max-width: 480px;
        width: 480px;
        min-height: 360px;
        height: 360px;
      }

       &--first {
       text-align: left;

        @media (min-width: 1200px) {
          margin-right: 12px;
        }

        @media (max-width: 500px) {
          max-width: 100%;
          width: 100%;
          min-height: 322px;
          height: 322px;
        }
      }

       &--second {
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 19px;

        @media (min-width: 1200px) {
          margin-left: 12px;
          margin-bottom: 0;
        }

        @media (max-width: 500px) {
          max-width: 100%;
          width: 100%;
          min-height: 287px;
          height: 287px;
        }
      }

      &--sm {
        margin-top: -9px;
        min-height: 176px;
        height: 176px;

        @media (min-width: 1200px) {
            display:none;
        }
      }
     
    }
    &__title {
      font-size: 17px;
      color: black;
      font-weight: bold;
      margin-bottom: 33px;

       @media (min-width: 501px) {
        font-size: 20px;
        }

      &--sm {
        font-size: 16px;
        margin-bottom: 20px;

        @media (min-width: 501px) {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }

      &--company-name {
        @media (min-width: 1199px) {
          margin-bottom: 2rem;
        }

      }

      &--blue {
        color: $blue-2;
      }

      &--capitalize {
        text-transform: capitalize;
      }
    }

    &__loan-detail {
      font-size: 14px;
      color: #545454;
      margin: 0;

      &--highlight {
        color: $blue-2;
      }
    }

    &__trust-pilot {
      margin-top: 10px;
      @media (min-width: 1199px) {
        margin-top: 40px;
      }
    }
  }
  
  .content-card {
    width: 100%;
    height: 114px;
    background-color: $color-white-1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 20px;

    @media (min-width: 1199px) {
        width: 268px;
    }

    &--white {
      background-color: $white;
    }

    &__title {
      font-size: 15px;
      color: $color-grey-3;
      margin:0;
    }
  }

  .title {
    font-size: 38px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
    color: $main-title-color;
    margin-bottom: 18px;
    text-align: center;
    max-width: 751px;
    margin-left: auto;
    margin-right: auto;

  @media (max-width: $screen-md) {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
     max-width: 516px;
  }
}

.subtitle {
  color: $color-grey-4;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

  @media (max-width: $screen-md) {
    font-size: 15px;
    line-height: 21px;
  }
}

.confirmation-page-money-test {
  .confirmation-page__title--blue,
  .confirmation-page__loan-detail--highlight {
    color: $blue-4;
  }

  .confirmation-page__container--first {
    .confirmation-page__title {
      color: $purple-1 !important;
    }
  }
}
</style>
